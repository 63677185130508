import React, { useState, useEffect, Fragment } from 'react';
import { Link as RouterLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ApiUrl from '../../services/ApiUrl'

export default function SessionDetail(props) {
  const { id } = props.match.params;
  const [ hasCookies, setHasCookies ] = useState();
  const [ userAgent, setUserAgent ] = useState();
  const [ time, setTime ] = useState();
  const [ sesstionStarted, setSesstionStarted ] = useState();
  const [ popID, setPopID ] = useState();
  const [ HTTPVersion, setHTTPVersion ] = useState();
  const [ clientIP, setClientIP ] = useState();
  const [ dnsResolver, setDnsResolver ] = useState();
  const [ sessionID, setSessionID ] = useState();
  const [ notFound, setNotFound ] = useState(false);


  useEffect(() => {
    fetch(
        `${ApiUrl.private}session/${id}`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json"
          })
        }
      )
        .then( res => res.json() )
        .then( res => res[0] )
        .then(response => {
          if (response) {
            setUserAgent(response.UserAgent);
            setTime(response.Timestamp);
            setClientIP(response.clientIP);
            setHTTPVersion(response.HTTPVersion);
            setSessionID(response.SessionID);
            setHasCookies(response.Cookies);
            if (response.DnsResolver) setDnsResolver(response.DnsResolver);
            setPopID(response.POPID);
            setSesstionStarted(true);
          } else {
            setNotFound(true);
          }
        })
        .catch(error => console.log(error));
  }, [ id, setHasCookies, setPopID, setUserAgent, setTime, setSesstionStarted, setHTTPVersion, setClientIP, setSessionID, setDnsResolver ]);
  
  return (
    <Fragment>
      <Button color="primary" component={RouterLink} to="/admin">
        Back to Session List
      </Button>
      { sesstionStarted ? (
        <Box my={4}>
          <Typography variant="h4" align="center" component="h1">
            <strong>Session ID:</strong>
          </Typography>
          <Typography variant="h4" align="center" component="h1">
            {sessionID}
          </Typography>
          <Typography variant="subtitle1" align="center" component="p" gutterBottom>
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography variant="h4" align="center" component="h1">
            <strong>Session Details:</strong>
          </Typography>
          <Typography align="center">
            <strong>POP ID:</strong> {popID}
          </Typography>
          <Typography align="center">
            <strong>Timestamp:</strong> {time}
          </Typography>
          <Typography align="center">
            <strong>User Agent:</strong> {userAgent}
          </Typography>
          <Typography align="center">
            <strong>Cookies:</strong> {hasCookies ? "Yes" : "No"}
          </Typography>
          <Typography align="center">
            <strong>HTTPVersion:</strong> {HTTPVersion}
          </Typography>
          <Typography align="center">
            <strong>ClientIP:</strong> {clientIP}
          </Typography>
          { dnsResolver && (
            <Typography align="center">
              <strong>DNS Resolver IP:</strong> {dnsResolver}
            </Typography>
          )}
        </Box>
       ) : (
        <Typography align="center">
          <strong>{ notFound ? 'Session ID Not Found' : 'Loading...' }</strong>
        </Typography>
       ) }
    </Fragment>
  );
}